import React from 'react';
import PropTypes from 'prop-types';
import Scrollspy from 'react-scrollspy';
import { graphql } from 'gatsby';

import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import Page from '~layout/Page';
import SEO from '~src/layout/SearchEngineOptimization';
import Container from '~components/Container';
import BMLessonPlanHero from '~components/bar-bat-mitzvah/lesson-plan/BMLessonPlanHero';
import BMLessonPlanWatch from '~components/bar-bat-mitzvah/lesson-plan/BMLessonPlanWatch';
import BMLessonPlanRead from '~components/bar-bat-mitzvah/lesson-plan/BMLessonPlanRead';
import BMLessonPlanConnect from '~components/bar-bat-mitzvah/lesson-plan/BMLessonPlanConnect';
import BMLessonPlanResources from '~components/bar-bat-mitzvah/lesson-plan/BMLessonPlanResources';
import unfurl from '~prismic/unfurl/playlist';

import RestrictedRoute from '~containers/restricted-route';
import { ACCESS_LEVELS } from '~utils/required-subscription-plan';

const seo = {
  seo_title: 'Bar and Bat Mitzvah Lesson Plan & Study Guides | Aleph Beta',
  seo_description:
    'Our Bar and Bat Mitzvah online lesson training helps parents guide their child’s studies so they can arrive spiritually and mentally prepared when they become of age.',
  seo_keywords:
    'bar mitzvah lesson plan, bar mitzvah study, bar mitzvah lessons, bat mitzvah lessons, bar mitzvah training online, online bat mitzvah training'
};

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1
  },
  bar_bat_mitzvah_prep_course: {
    backgroundColor: '#f1eee9',
    backgroundImage: 'url(/images/bar-bat-mitzvah/bmpl-bg.png)',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center top',
    backgroundAttachment: 'fixed',
    WebkitBackgroundSize: 'contain',
    MozBackgroundSize: 'contain',
    OBackgroundSize: 'contain',
    backgroundSize: 'contain',
    fontFamily: "'Raleway', sans-serif",
    '& target:before': {
      content: "''",
      display: 'block',
      height: '119px',
      marginTop: '-119px'
    }
  },
  bbmpc_navbar: {
    position: 'sticky',
    top: '48px',
    zIndex: '2',
    padding: '0'
  },
  tab_navbar: {
    backgroundColor: '#fad188'
  },
  tab: {
    paddingTop: '20px',
    paddingBottom: '20px',
    fontSize: '16px'
  }
}));

const CONTENT = {
  PLAYLIST_SLUG: 'bar-bat-mitzvah-preparation',
  QUIZZES: [
    {
      title: 'Segment 1 Quiz',
      link: 'https://goo.gl/forms/lkUjIrWsxEwiSeaE3'
    },
    {
      title: 'Segment 2 Quiz',
      link: 'https://goo.gl/forms/uyRFHlIMcRe5ciBJ2'
    },
    {
      title: 'Segment 3 Quiz',
      link: 'https://goo.gl/forms/7vsdRGFCGvtjsXbo2'
    },
    {
      title: 'Segment 4 Quiz',
      link: 'https://goo.gl/forms/fkER82xeWfId8Msw1'
    },
    {
      title: 'Segment 5 Quiz',
      link: 'https://goo.gl/forms/lvLAua0OOOZWvkMN2'
    }
  ],
  WELCOME_GUIDES: [
    {
      title: 'Welcome and Course Instructions (for Parents)',
      link: 'https://prismic-io.s3.amazonaws.com/aleph-beta%2F5a35652f-a247-400a-91d6-f6d96ad1cbc0_bar-mitzvah-welcome-instructions.pdf'
    },
    {
      title: 'Bar and Bat Mitzvah Check-list',
      link: 'https://prismic-io.s3.amazonaws.com/aleph-beta%2Fbdb1fe1a-2376-4600-9be4-2d3b78836eab_bar-mitzvah-checklist.pdf'
    }
  ],
  COURSE_GUIDES: [
    {
      title: 'Click here',
      link: 'https://prismic-io.s3.amazonaws.com/aleph-beta%2F2f47b93a-4481-4969-8436-9a96d391711d_bar-mitzvah-guide.pdf'
    }
  ],
  RESOURCES: {
    exercises: [
      {
        title: 'Exercise',
        description: '',
        link: 'https://prismic-io.s3.amazonaws.com/aleph-beta%2F6dce5400-2b5c-406e-bcef-cd188a6283f8_bar-mitzvah-exercises.pdf'
      }
    ],
    podcasts: [
      {
        title: 'Bar & Bat Mitzvah Podcast',
        description: '',
        link: '/playlist/bar-bat-mitzvah-study-guide'
      }
    ],
    manuals: [
      {
        title: `Parent's Manual`,
        description: '',
        link: 'https://prismic-io.s3.amazonaws.com/aleph-beta%2F3658608d-8cb0-4ed7-853b-81ffe032daa0_bar-mitzvah-preparation.pdf'
      }
    ]
  }
};

const BMLessonPlan = props => {
  const classes = useStyles();
  const { data } = props;
  const { videos } = unfurl(data);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only('xs'));

  const [value, setValue] = React.useState(0);

  const minAccessLevel = ACCESS_LEVELS.BARBATMITZVAH;

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleScrollChange = e => {
    const id = e && e.id;
    switch (id) {
      case 'watch':
        setValue(0);
        break;
      case 'read':
        setValue(1);
        break;
      case 'connect':
        setValue(2);
        break;
      case 'resources':
        setValue(3);
        break;
      default:
        break;
    }
  };

  return (
    <Page>
      <RestrictedRoute minAccessLevel={minAccessLevel}>
        <SEO {...seo} />
        <div className={classes.bar_bat_mitzvah_prep_course}>
          <BMLessonPlanHero id="hero" videos={videos} welcomeGuides={CONTENT.WELCOME_GUIDES} />
          <div className={classes.bbmpc_navbar}>
            <Container>
              <Grid container>
                <Grid item xs={12}>
                  <div className={classes.tab_navbar}>
                    <Scrollspy
                      items={['watch', 'read', 'connect', 'resources']}
                      componentTag="div"
                      onUpdate={handleScrollChange}
                    >
                      <Tabs
                        value={value}
                        onChange={handleChange}
                        centered={!isMobile}
                        variant={isMobile ? 'scrollable' : 'fullWidth'}
                      >
                        <Tab href="#watch" label="Watch" className={classes.tab} />
                        <Tab href="#read" label="Read" className={classes.tab} />
                        <Tab href="#connect" label="Connect" className={classes.tab} />
                        <Tab href="#resources" label="Resources" className={classes.tab} />
                      </Tabs>
                    </Scrollspy>
                  </div>
                </Grid>
              </Grid>
            </Container>
          </div>
          <BMLessonPlanWatch videos={videos} quizzes={CONTENT.QUIZZES} />
          <BMLessonPlanRead courseGuides={CONTENT.COURSE_GUIDES} />
          <BMLessonPlanConnect />
          <BMLessonPlanResources {...CONTENT.RESOURCES} />
        </div>
      </RestrictedRoute>
    </Page>
  );
};

BMLessonPlan.propTypes = {
  data: PropTypes.shape().isRequired
};

export default BMLessonPlan;

export const query = graphql`
  query {
    prismicPlaylist(uid: { eq: "bar-bat-mitzvah-preparation" }) {
      uid
      prismicId
      data {
        title
        required_subscription_plan
        description {
          html
          text
        }
        cover_image {
          url
          alt
        }
        author {
          document {
            ... on PrismicAuthor {
              data {
                name
                role
                avatar {
                  alt
                  url
                }
              }
            }
          }
        }
        videos {
          video {
            document {
              ... on PrismicVideo {
                uid
                prismicId
                data {
                  title
                  description {
                    html
                    text
                  }
                  cover_image {
                    alt
                    url
                  }
                  wistia_url {
                    url
                  }
                  transcript {
                    html
                    text
                  }
                }
              }
            }
          }
        }
        seo_title
        seo_description
        seo_keywords
        seo_image {
          url
        }
      }
    }
  }
`;
